import styled from "styled-components";
import React, { useContext, useState, useRef } from "react";
import Title from "../Components/Title";
import { ThemeContext } from "../context";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com";

function ContactPage() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const formRef = useRef(null);
  const [done, setDone] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_w293grn",
        "template_s9zb2mj",
        formRef.current,
        "GSxGv84n6_S1qOFqL"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
    event.target.reset();
  };

  return (
    <ContactStyled darkMode={darkMode}>
      <Helmet>
        <title>Amrindra | Contact Me</title>
        <meta name="description" content="Portfolio" />
        <link rel="canonical" href="/contact" />
      </Helmet>

      <div className="container">
        <Title tittle={"Contact Me"} span={"contact Me"} />

        {/* <p style={{ color: darkMode && "white" }}>
          <img
            src="https://img.icons8.com/emoji/100/000000/light-bulb-emoji.png"
            alt=""
            className="light-buld"
          />
          Need a collaboration with your projects? or have any questions? please
          do not hesitate to contact me.
        </p> */}

        <div className="contact-form">
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={darkMode ? "dark-mode-form" : "light-mode-form"}
          >
            <Fade top>
              <div className="form-group">
                <label htmlFor="username">
                  Your Full Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  required
                  className="form-element"
                  // placeholder="Your Name"
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  // style={{ backgroundColor: darkMode && "#222" }}
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="form-element"
                  // placeholder="abc@example.com"
                />
              </div>

              <div className="form-group full">
                <label htmlFor="message">
                  Message<span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  // style={{ backgroundColor: darkMode && "#222" }}
                  id="message"
                  name="message"
                  row="5"
                  className="form-element"
                  placeholder=""
                  required
                ></textarea>
              </div>

              <div className="submit-group">
                <button
                  type="submit"
                >
                  {done ? (
                    <h3>
                      Sent!! <br />
                      Thank You, I will response as soon as I can.
                    </h3>
                  ) : (
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/external-prettycons-flat-prettycons/47/external-send-social-media-prettycons-flat-prettycons.png"
                      alt="external-send-social-media-prettycons-flat-prettycons"
                    />
                  )}
                </button>
              </div>
            </Fade>
          </form>
        </div>
      </div>
    </ContactStyled>
  );
}

const ContactStyled = styled.section`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-bottom: 4rem;
  position: relative;

  /* p {
    color: #888;
    line-height: 1.5;
    margin-bottom: 32px;
    padding-top: 40px;
    padding-bottom: 10px;
    font-size: 1.5rem;
  } */

  /* .light-buld {
    height: 24px;
    width: 24px;
  } */

  .contact-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;

    .light-mode-form,
    .dark-mode-form {
      width: 70%;
      padding: 32px;
      border-radius: 10px;
      /* background-color: ${({ darkMode }) => darkMode && "#545863;"}; */
      box-shadow: 0 9px 15px rgba(0, 0, 0, 0.8);
      z-index: 1;

      .form-group {
        grid-column: 1 / -1;
      }

      .form-group > label {
        display: block;
        margin-bottom: 5px;
        margin-top: 10px;
        font-size: 15px;
      }

      .form-element {
        /* background-color: #d8e3e7; */
        appearance: none;
        outline: none;
        border: none;
        display: block;
        width: 100%;
        border-radius: 8px;
        padding: 12px 16px;
        transition: 0.4s;
        box-shadow: 0 9px 15px rgba(0, 0, 0, 0.1);
      }

      textarea {
        resize: none;
        min-height: 100px;
      }

      .submit-group {
        padding-top: 1rem;
        text-align: center;

        button {
          appearance: none;
          border: none;
          outline: none;
          background-color: none;
          width: 15vw;
          padding: 12px 16px;
          background-color: #494949;
          border-radius: 10px;
          color: white;
          cursor: pointer;
          transition: 0.4s;
          font-weight: bold;
          font-size: 1.1rem;

          &:hover {
            transform: scale(1.04);
          }

          @media (max-width: 678px) {
            width: 20vw;
          }
        }
      }
    }

    .dark-mode-form {
      background-color: none;
      border: 2px solid white;
      filter: drop-shadow(0.6rem 0.6rem 1rem rgba(20, 20, 180, 0.8));

      .submit-group {
        button {
          background: none;
          background-color: none;
          border: 1px solid white;
          transition: all 1s;

          &:hover {
            background-color: #120055;
          }
        }
      }
    }
  }

  /* .map-section {
      flex-grow: 1;

      .map {
        border-radius: 16px;
        box-shadow: 0 9px 15px rgba(0, 0, 0, 0.8);
      }
    }
  } */

  @media screen and (max-width: 675px) {
    .contact-form {
      flex-direction: column;

      .light-mode-form,
      .dark-mode-form {
        @media (max-width: 678px) {
          width: 100%;
        }
      }
    }

    p {
      font-size: 1rem;
      padding-top: 10px;
    }
  }
`;

export default ContactPage;
