import React, { useContext, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { ThemeContext } from "../context";
import Jello from "react-reveal/Jello";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import { motion, useScroll, useTransform } from "framer-motion";
import starsImage from "../images/stars.png";
import planetImage from "../images/planets.png";
import sunImage from "../images/sun.png";

function Intro() {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [sliderXValue, setSliderXValue] = useState("-390%");
  const ref = useRef();

  // SCROLL ANIMATION SECTION FROM REACT FRAMER MOTION
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const yText = useTransform(scrollYProgress, [0, 1], ["0%", "350%"]);
  const yIcon = useTransform(scrollYProgress, [0, 1], ["0%", "120%"]);
  const yBgStars = useTransform(scrollYProgress, [0, 1], ["-5%", "100%"]);
  const yBgPlanet = useTransform(scrollYProgress, [0, 1], ["0%", "70%"]);

  useEffect(() => {
    const updateSliderXValue = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSliderXValue("-800%"); // Adjust for smaller screens
      } else {
        setSliderXValue("-390%"); // Default value for larger screens
      }
    };

    updateSliderXValue(); // Set initial value
    window.addEventListener("resize", updateSliderXValue); // Update on resize

    return () => window.removeEventListener("resize", updateSliderXValue); // Clean up
  }, []);

  const sliderVariants = {
    initial: {
      x: 0,
    },
    animate: {
      x: sliderXValue,
      transition: {
        repeat: Infinity,
        repeatType: "mirror",
        duration: 16,
      },
    },
  };

  return (
    <IntroStyled darkMode={darkMode} ref={ref}>
      <Helmet>
        <title>Amrindra | Homepage</title>
        <meta name="description" content="Portfolio" />
      </Helmet>

      {/* Stars background */}
      <motion.div
        className="stars"
        style={{
          y: yBgStars,
          backgroundImage: `url(${starsImage})`,
        }}
      ></motion.div>

      {/* Planet background */}
      <motion.div
        className="planets"
        style={{
          y: yBgPlanet,
          backgroundImage: `url(${darkMode ? planetImage : sunImage})`,
          // Conditionally add the transition when darkMode changes
          transition:
            darkMode !== undefined
              ? "background-image 1.5s ease-in-out"
              : "none",
        }}
      ></motion.div>

      <div className="introduction">
        <motion.div className="intro-name-wrapper" style={{ y: yText }}>
          <h1 style={{ color: darkMode ? "white" : "#3f3351" }}>Hi, I'm </h1>
          <span style={{ color: darkMode && "red" }} className="my-name">
            Amrindra
          </span>
        </motion.div>

        <motion.div className="intro-title" style={{ y: yText }}>
          <div
            className="intro-title-wrapper"
            style={{ color: darkMode ? "white" : "#334756" }}
          >
            <div className="intro-title-item">A Passionate Developer</div>
            <div className="intro-title-item">Problem Solving</div>
            <div className="intro-title-item">Eager to Learn</div>
            <div className="intro-title-item">A Motivated Self-Starter</div>
            <div className="intro-title-item">Adaptability</div>
          </div>
        </motion.div>

        <div className="body-text">
          <Fade left>
            <h3 className="body-text">
              I'm a technology enthusiast who loves solving problems with
              programming & designing responsive websites.
            </h3>
          </Fade>
        </div>

        <Jello>
          {/* <motion.div style={{ y: yIcon }}> */}
          <div className="icons">
            <a
              href="https://github.com/AmrindraOuk"
              target="_blank"
              rel="noreferrer"
              aria-label="Check out more about Amrindra's project on github acount"
              className="icon i-github"
            >
              <img
                src="https://img.icons8.com/fluency/100/000000/github.png"
                alt="github icon"
              />
            </a>

            <a
              href="mailto: oamrindra@gmail.com"
              target="_blank"
              rel="noreferrer"
              aria-label="Reach out to Amrindra via email"
              className="icon i-email"
            >
              <img
                src="https://img.icons8.com/color/100/circled-envelope.png"
                alt="email icon"
              />
            </a>

            <a
              href="https://www.linkedin.com/in/amrindraouk-1260161a9d/"
              target="_blank"
              rel="noreferrer"
              aria-label="Amrindra ouk's Linkedin Profile"
              className="icon i-email"
            >
              <img
                src="https://img.icons8.com/color/100/linkedin-circled--v1.png"
                alt="email icon"
              />
            </a>
          </div>
          <button className="credly-badges-button">
            <a
              href="https://www.credly.com/users/amrindra-ouk"
              className="download-button"
              target="_blank"
              rel="noreferrer"
              aria-label="Amrindra's Credy Link for accomplished badges"
            >
              {/* <Button title="Check out my badges on Credly" /> */}
              Check out my badges on Credly.
            </a>
          </button>
          {/* </motion.div> */}
        </Jello>

        <motion.div
          className="sliding-text-container"
          variants={sliderVariants}
          initial="initial"
          animate="animate"
        >
          A Passionate Software Engineer.
          {/* Python, SQL, JS, React */}
        </motion.div>
      </div>
    </IntroStyled>
  );
}

const IntroStyled = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
  text-align: center;
  padding-top: 3rem;
  position: relative;
  /* background-image: url(${starsImage}); */

  .stars {
    background-image: url("/stars.png");
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    /* z-index: 1; */
  }

  .planets {
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 20px;

    @media screen and (min-width: 1600px) {
      height: 70vh;
      top: -30px;
    }

    @media screen and (min-width: 1921px) {
      /* Adjust for wide screens */
      background-size: contain; /* You may want to change how the background is displayed */
      background-position: center;
      /* background-repeat: no-repeat; */
      top: -10px;
      bottom: unset; /* Avoid conflicting styles */
    }

    /* @media screen and (min-width: 912px) {
      height: 45vh;
    } */

    @media screen and (max-width: 768px) {
      background-size: contain;
      background-repeat: no-repeat;
      bottom: 250px;
    }
  }

  .introduction {
    .intro-name-wrapper {
      display: flex;
      justify-content: center;
      align-items: baseline;
      gap: 1rem;

      h1 {
        letter-spacing: 0.3rem;
      }
    }

    .body-text {
      margin: 70px 0 80px 0;
      font-size: 1.6crem;
      letter-spacing: 0.15rem;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }

    .sliding-text-container {
      position: absolute;
      top: 350px;
      // font-size: 30vh;
      font-size: 18vh;
      /* bottom: -120px; */
      white-space: nowrap;
      color: ${({ darkMode }) => (darkMode ? "#ffffff1a" : "lightgray")};
      width: 50%;
      font-weight: bold;
      pointer-events: none;
      /* Sliding text doesn't work with z-index -1 when darkMode is true. Only work when darkMode is false */
      z-index: ${({ darkMode }) => !darkMode && -1};
    }
  }

  .my-name {
    background: linear-gradient(
      90deg,
      rgba(20, 10, 36, 1) -30%,
      rgba(115, 167, 223, 1) 50%,
      rgba(92, 162, 213, 1) 18%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    -moz-background-clip: text;
    background-clip: text;
    font-size: 4.5rem;
  }

  /* icons */
  img {
    width: 60px;
    height: 60px;
    transition: 900ms;
    z-index: 2;

    &:hover {
      transform: scale(1.2);
      transition: 900ms;
    }
  }

  .intro-title {
    height: 50px;
    overflow: hidden;
    margin-top: 1rem;
    /* margin: 20px 0; */

    @media screen and (max-width: 1024px) {
      height: 45px;
      margin-top: 1rem;
    }

    .intro-title-wrapper {
      height: 100%;
      animation: move 9s ease-in-out infinite alternate;

      @keyframes move {
        25% {
          transform: translateY(-50px);
        }
        50% {
          transform: translateY(-100px);
        }
        75% {
          transform: translateY(-150px);
        }
        100% {
          transform: translateY(-200px);
        }
      }

      .intro-title-item {
        height: 50px;
        font-size: 25px;
        font-weight: bold;
      }
    }
  }

  .icons {
    position: relative;
    z-index: 99;
    .icon {
      padding: 0 5px;
    }
  }

  .credly-badges-button {
    padding: 6px;
    border: 1px solid ${({ darkMode }) => (darkMode ? "white" : "black")};
    border-radius: 2px;
    background-color: transparent;
    color: ${({ darkMode }) => (darkMode ? "white" : "black")};
    margin-right: 20px;
    font-weight: 300;
    margin-top: 15px;
    position: relative;
    z-index: 2;
    cursor: pointer;

    .download-button {
      font-size: 1rem;
    }
  }

  /* .global-button {
    border: none;
    color: white;
    background: linear-gradient(
      90deg,
      rgba(40, 197, 226, 0.8) 0%,
      rgba(154, 183, 249, 0.74) 100%
    );
  } */

  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 3rem;
    }
  }

  /* ************************ */
  @media screen and (max-width: 768px) {
    h1,
    span {
      /* margin-top: 7rem; */
      font-size: 2.5rem;
    }

    h3 {
      font-size: 1.2rem;
      margin: 30px 0 20px 0;
    }

    .body-text {
      margin: 3rem 0;
    }
  }

  /* *************************************** */
  /* min-width */
  @media screen and (min-width: 768px) {
    h1 {
      /* margin-top: 7rem; */
      font-size: 3.5rem;

      span {
        font-size: 4rem;
      }
    }

    .body-text {
      margin: 2rem 0;

      h3 {
        font-size: 1.5rem;
      }
    }
    /* button {
      font-size: 1.4rem;
    } */
  }

  /* *************************************** */

  @media screen and (max-width: 684px) {
    .introduction {
      margin-top: -40px;
      .intro-name-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          font-size: 3rem;
          margin-top: -10px;
        }
      }

      .sliding-text-container {
        top: 350px;
        font-size: 15vh;
      }
    }
  }

  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

export default Intro;
